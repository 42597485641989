import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client/core'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import { createApolloProvider } from '@vue/apollo-option'
import BootstrapVue3 from 'bootstrap-vue-3'
import gql from "graphql-tag";
import { createApp } from 'vue';
import { createMetaManager, defaultConfig } from 'vue-meta'
import App from './App.vue';
import router from "./router/router"
import store from './store/store';

// Setting up apollo links
const httpLinkBackend = new HttpLink({
	uri: process.env.VUE_APP_BACKEND_URL + '/graphql',
});

const httpLinkEvents = new HttpLink({
	uri: process.env.VUE_APP_EVENTS_GRAPHQL,
});

// Cache implementation
const cache = new InMemoryCache()

const clientBackend = new ApolloClient({
	link: httpLinkBackend,
	cache
});
const clientEvents = new ApolloClient({
	link: httpLinkEvents,
	cache
});

const apolloProvider = new createApolloProvider({
	clients: {
		clientBackend,
		clientEvents
	},
	defaultClient: clientBackend
})

const metaManager = createMetaManager(false, {
	...defaultConfig,
	meta: { tag: 'meta', nameless: true },
});

const vm = createApp(App);
vm.use(apolloProvider);
vm.use(BootstrapVue3);
vm.use(router);
vm.use(metaManager);
vm.use(VueGoogleMaps, {
	load: {
		key: process.env.VUE_APP_VANPLAN_GOOGLE_MAPS_API_KEY,
	},
});

vm.init = function bf() {
	// Get config variables.
	apolloProvider.clients.clientBackend.query({
		query: gql`
		  query site {
			site {
				data {
					attributes {
						General {
							Logo {
								data {
									attributes {
										url
										formats
									}
								}
							}
						}
						Homepage {
							Title
							Text
						}
						Footer {
							Text
						}
						homepageseo {
							metaTitle
							metaDescription
							keywords
							metaRobots
							structuredData
							metaViewport
							canonicalURL
							metaImage {
								data {
									attributes {
										formats
										name
										url
										width
										height
										mime
									}
								}
							}
							metaSocial {
								socialNetwork
								title
								description
								image {
									data {
										attributes {
											formats
											name
											url
											width
											height
											mime
										}
									}
								}
							}
						}
					}
				}
			}
		}`
	}).then((data) => {
		// Set config variables.
		if (data?.data?.site?.data?.attributes?.General?.Logo?.data?.attributes?.formats?.large) {
			store.setConfig('general', 'logo', process.env.VUE_APP_BACKEND_URL + data.data.site.data.attributes.General.Logo.data.attributes.formats.large.url);
		} else if (data?.data?.site?.data?.attributes?.General?.Logo?.data?.attributes?.url) {
			store.setConfig('general', 'logo', process.env.VUE_APP_BACKEND_URL + data.data.site.data.attributes.General.Logo.data.attributes.url);
		}

		if (data?.data?.site?.data?.attributes?.Homepage?.Title) {
			store.setConfig('homepage', 'title', data.data.site.data.attributes.Homepage.Title);
		}

		if (data?.data?.site?.data?.attributes?.Homepage?.Text) {
			store.setConfig('homepage', 'text', data.data.site.data.attributes.Homepage.Text);
		}

		if (data?.data?.site?.data?.attributes?.Footer?.Text) {
			store.setConfig('footer','text', data.data.site.data.attributes.Footer.Text);
		}

		if (data?.data?.site?.data?.attributes?.homepageseo) {
			store.setConfig('homepageseo','seo', data?.data?.site?.data?.attributes?.homepageseo);
		}

		this.mount('#app');
	});
};

vm.init();

export default apolloProvider
