<template>
	<div class="home-header">
    <b-container>
      <b-row>
        <b-col cols="12" lg="8" offset-lg="2">
          <h2>{{ title }}</h2>
          <div v-html="text"></div>
        </b-col>
      </b-row>
    </b-container>
	</div>
</template>

<script>
import store from '../store/store';

export default {
	name: "HomeText",
	data() {
		return {
      title: store.getConfig('homepage', 'title'),
			text: store.getConfig('homepage', 'text'),
		};
	},
};
</script>

<style lang="scss">
@import '../assets/scss/_variables.scss';
.home-header {
  background-color: #000;
  color: #fff;
  padding: $spacing-6xl 0 $spacing-10xl;

  h2 {
    font-size: $fs-6xl;
    font-weight: $fw-black;
    margin-bottom: $spacing-xl;
  }

  p {
    font-size: $fs-lg;
    font-weight: $fw-medium;
    line-height: $lh-sm;
    margin: 0;
  }

  @media #{$tablet-and-above} {
    padding: $spacing-lg 0 $spacing-2xl;

    h2 {
      font-size: $fs-9xl;
      margin-bottom: $spacing-5xl;
    }

    p {
      font-size: $fs-2xl;
      line-height: $lh-regular;
    }
  }
}
</style>
