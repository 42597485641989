import { createRouter,
    createWebHistory
} from 'vue-router'
import Home from "@/components/Home";
import SinglePost from "@/components/SinglePost";
import Event from "@/components/Event";


const routes = [
    {
        path: '/',
        component: Home,
        name: 'home'
    },
    {
        path: '/:slug',
        component: SinglePost,
        name: 'post'
    },
    {
        path: '/uitje/:id',
        component: Event,
        name: 'event'
    },
]

const router = new createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        // Scroll to top every route.
        return { top: 0 };
    },
});

export default router
