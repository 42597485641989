<template>
  <div class="event-carousel">
    <Carousel :wrap-around="true">
      <Slide v-for="(event, index) in ticketingEvents" :key="index" class="slide">
        <div class="carousel__item">
          <EventFeatured :event="event"></EventFeatured>
        </div>
      </Slide>

      <template #addons>
        <Navigation />
        <Pagination />
      </template>
    </Carousel>
  </div>
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import EventFeatured from "@/components/EventFeatured";
import 'vue3-carousel/dist/carousel.css'

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    EventFeatured,
  },
  props: {
    ticketingEvents: {
      type: Array,
      required: true
    }
  },
};


</script>

<style>
.event-carousel {
  padding: 0 20px;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 100%;



  .carousel__prev,
  .carousel__next,
  .carousel__pagination-button {
    box-shadow: none;
  }
}
    .carousel__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 100%;
      max-width: 500px;

      .information-container {
        align-items: flex-start;

        .vp-location,
        .vp-date {
          text-align: left;
        }
      }
    }
</style>
