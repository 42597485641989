/**
 * Project constants.
 */

const constantImageSizes = {
  VANPLAN_THUMB_INFIX: '_image',
  VANPLAN_FEATURED_INFIX: '_image_big',
  VANPLAN_RELATED_INFIX: '_image_homepage',
};

export default constantImageSizes;
