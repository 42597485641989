import gql from "graphql-tag";
import moment from 'moment';
import _ from "lodash";
import apolloProvider from "@/main";
import constantImageSizes from "@/plugins/constants";
import { useMeta } from 'vue-meta';

export default {
  methods: {
    setSeoMeta(seo, extra = {}) {
      let meta1 = [];
      let ogTitle = seo.metaTitle;
      let ogDescription = seo.metaDescription;
      let ogImage = '';
      let ogImageWidth = '';
      let ogImageHeight = '';
      let ogImageType = '';

      // Check what type of image we have. If .formats is available there is access to images sizes like large, medium and small.
      // Otherwise is't probably an svg and those sizes aren't available.
      if (seo?.metaImage?.data?.attributes?.formats?.large) {
        ogImage = process.env.VUE_APP_BACKEND_URL + seo.metaImage.data.attributes.formats.large.url;
        ogImageWidth = seo.metaImage.data.attributes.formats.large.width;
        ogImageHeight = seo.metaImage.data.attributes.formats.large.height;
        ogImageType = seo.metaImage.data.attributes.formats.large.mime;
      } else if (seo?.metaImage?.data?.attributes?.url) {
        ogImage = process.env.VUE_APP_BACKEND_URL + seo.metaImage.data?.attributes.url;
        ogImageType = seo.metaImage.data?.attributes.mime;
      }

      // Add social tags.
      if (seo.metaSocial !== undefined) {
        var i = 0;

        while ( i < seo.metaSocial.length ) {
          if (seo.metaSocial[i].socialNetwork === 'Twitter' ) {
            meta1.push({property: 'twitter:card', content: 'summary'});

            if (seo.metaSocial[i].title ) {
              meta1.push({property: 'twitter:title', content: seo.metaSocial[i].title});
            }
            if (seo.metaSocial[i].description ) {
              meta1.push({property: 'twitter:description', content: seo.metaSocial[i].description});
            }
            if (seo?.metaSocial[i]?.image?.data?.attributes?.formats?.medium?.url ) {
              meta1.push({property: 'twitter:image', content: process.env.VUE_APP_BACKEND_URL + seo.metaSocial[i].image.data.attributes.formats.medium.url});
            } else if ( this?.seo?.metaSocial[i]?.image?.data?.attributes?.url ) {
              meta1.push({property: 'twitter:image', content: process.env.VUE_APP_BACKEND_URL + seo.metaSocial[i].image.data.attributes.url});
            }
          } else if (seo.metaSocial[i].socialNetwork === 'Facebook' ) {
            if (seo.metaSocial[i].title ) {
              ogTitle = seo.metaSocial[i].title;
            }
            if (seo.metaSocial[i].title ) {
              ogDescription = seo.metaSocial[i].description;
            }

            // Check what type of image we have. If .formats is available there is access to images sizes like large, medium and small.
            // Otherwise is't probably an svg and those sizes aren't available.
            if ( seo?.metaSocial[i]?.image?.data?.attributes?.formats?.medium?.url ) {
              ogImage = process.env.VUE_APP_BACKEND_URL + seo.metaSocial[i].image.data.attributes.formats.medium.url;
              ogImageWidth = seo.metaSocial[i].image.data.attributes.formats.medium.width;
              ogImageHeight = seo.metaSocial[i].image.data.attributes.formats.medium.height;
              ogImageType = seo.metaSocial[i].image.data.attributes.formats.medium.mime;
            } else if ( seo?.metaSocial[i]?.image?.data?.attributes?.url ) {
              ogImage = process.env.VUE_APP_BACKEND_URL + seo.metaSocial[i].image.data.attributes.url;
              ogImageType = seo.metaSocial[i].image.data.attributes.mime;
            }
          }
          i++;
        }
      }

      let meta2 = [
        {name: 'description', content: seo.metaDescription},
        {name: 'robots', content: seo.metaRobots},
        {name: 'viewport', content: (seo.metaViewport) ? seo.metaViewport : 'width=device-width, initial-scale=1'},
        {name: 'keywords', content: (seo.keywords) ? seo.keywords : ''},
        {property: "og:locale", content: 'nl_NL'},
        {property: "og:type", content: 'article'},
        {property: "og:title", content: ogTitle},
        {property: 'og:description', content: ogDescription},
        {property: 'og:url', content: window.location.href},
        {property: 'og:site_name', content: 'VanPlan'},
        {property: 'og:image', content: ogImage},
        {property: 'og:width', content: ogImageWidth},
        {property: 'og:type', content: ogImageType},
        {property: 'og:height', content: ogImageHeight},
      ];

      // Get the values out of extra.
      if (extra?.createdAt) {
        meta2.push({property: 'article:published_time', content: extra.createdAt});
      }

      if (extra?.createdAt) {
        meta2.push({property: 'article:modified_time', content: extra.updatedAt});
      }

      const metaAll = meta2.concat(meta1);
      let metaResult = [];

      // Get the elements with a non empty content property.
      for (var j=0; j<metaAll.length; j++) {
        if (metaAll[j].content) {
          metaResult.push(metaAll[j]);
        }
      }

      useMeta({
        meta: metaResult,
      });

      if (seo?.metaTitle && seo.metaTitle) {
        useMeta({
          title: seo.metaTitle + ' | VanPlan Tickets',
        });
      }

      if (seo?.canonicalURL && seo.canonicalURL) {
        useMeta({
          link: [{rel: 'canonical', href: seo.canonicalURL},],
        });
      }

      if (seo?.structuredData && seo.structuredData) {
        useMeta({
          script: [{
            type: 'application/ld+json',
            json: JSON.stringify(seo.structuredData)
          }],
        });
      }
    },
    formatEventCityName(event) {
      const location = [];
      if (event.location.name) {
        location.push(event.location.name);
      }

      if (event.location.cityName) {
        // Change cityname to be all lowercase
        let city = event.location.cityName.toLowerCase();
        // Capitalize first letter of cityname
        city = city.charAt(0).toUpperCase() + city.slice(1);
        location.push(city);
      }
      return location.join(', ');
    },
    formatEventprice(event) {
      let result = '';

      if (event.isFree) {
        result = 'Gratis';
      } else if (event.adultFee) {
        result = this.formatPrice(event.adultFee);
      }
      return result;
    },
    formatDate(date, format = 'dddd D MMMM', formatYear = 'dddd D MMMM YYYY', prefix = '', useYearFormat = false) {
      let thisFormat = format;

      if (date === '' || typeof date === 'undefined') {
        return 'Invalid date';
      }

      // If the supplied date contains a year in the future or
      if (useYearFormat || moment(date).year() - moment().year() >= 1 || moment(date).year() - moment().year() < 0) {
        thisFormat = formatYear;
      }

      return `${prefix}${moment(date).format(thisFormat)}`;
    },
    formatPrice(price) {
      const formatter = new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR',
      });
      return formatter.format(price);
    },
    formatUrl(url) {
      // Ensure https:// is in the URL.
      if (!url.match(/^[a-zA-Z]+:\/\//)) {
        return `https://${url}`;
      }

      return url;
    },
    getCategories: function getCategories() {
      const catQuery = gql`
        query {
            categories {
              id
              name
              parentId
          }
        }
      `;

      return apolloProvider.clients.clientEvents.query({
        query: catQuery,
        client: 'clientEvents',
      }).then((response) => {
          const categories = response.data.categories;
          const parents = categories.filter(cat => !cat.parentId);

          // Sort by name
          const sortedParents = _.orderBy(parents, ['name'], 'asc');

          return sortedParents;
        });
    },
    getEvent: function getEvent(id) {
      const eventQuery = gql`
        query($id: Long!) {
          event(id: $id) {
            id
            title
            widgetIntro
            masterHtmlContent
            description
            subtitle
            location {
              name
              streetName
              houseNumber
              houseNumberAddition
              postalCode
              cityName
              phoneNumber
              emailAddress
              latitude
              longitude
              url
              province
            }
            images {
              url
              isMain
              copyright
            }
            videos {
              id
              name
              description
              youtubeCode
            }
            tickets {
              description
              initialPrice
              finalPrice
              url
              title
            }
            startDate
            endDate
            occurrences {
              id
              startDate
              startTime
              endDate
              endTime
              isSoldOut
              isCancelled
              isPostponed
              newDate
            }
            isPostponed
            isCancelled
            isSoldOut
            isFree
            adultFee
            isAdultFree
            childFee
            isChildFree
            childFeeStartAge
            childFeeEndAge
            isCjpFree
            cjpFee
            isSeniorFree
            seniorFee
            hasPresale
            presaleCjpFee
            presaleAdultFee
            presaleChildFee
            presaleSeniorFee
            presaleDescription
            presaleChildFeeEndAge
            presaleChildFeeStartAge
            isPresaleCjpFree
            isPresaleAdultFree
            isPresaleChildFree
            isPresaleSeniorFree
            url
            url2
            url3
            urlFacebook
            urlTwitter
            masterDescription
            ticketSale
            vanplanPrice
            isLongRunning
          }
        }
      `;

      return apolloProvider.clients.clientEvents.query({
        query: eventQuery,
        client: 'clientEvents',
        variables: {
          id,
        },
      })
        .then((response) => {
          const thisEvent = { ...response.data.event }; // Clone object because source is read only.

          // Replace \r\n\r\n with <br><br>.
          if (thisEvent.description) {
            thisEvent.description = thisEvent.description.replace(
              /\r\n\r\n/g,
              '<br><br>',
            );
          }

          // Place images marked with isMain at the start and get url with imagesize.
          thisEvent.images = this.sortImages(
            thisEvent.images,
            constantImageSizes.VANPLAN_FEATURED_INFIX,
          );

          return thisEvent;
        });
    },
    getEvents: function getEvents(filterOptions = {}, page = 1, resultsPerPage = 4) {
      const filters = filterOptions;

      // Add empty locationExcludes if not exists.
      // That saves us checking for that property several times.
      if (!Object.prototype.hasOwnProperty.call(filters, 'locationExcludes')) {
        filters.locationExcludes = {
          locationNames: [],
          exceptionVisitorCategoryIds: [],
        };
      }
      /*
      exceptionPriceRange: {
            fromPrice: '',
            toPrice: '',
          },
       */

      const eventsQuery = gql`
        query($filters: InputFilters!, $page: InputPage!) {
          eventQuery(filters: $filters, page: $page, order: [
            {direction: ASC, field: IS_EXHIBITION}
            {field: OCCURRENCE_COUNT, direction: ASC}
            {field: STARTDATE, direction: ASC}
          ]) {
            totalResults
            events {
              id
              title
              occurrenceCount
              description
              startDate
              endDate
              location {
                name
                cityName
              }
              adultFee
              isFree
              isPostponed
              isCancelled
              isSoldOut
              images {
                url
                isMain
              }
              categories {
                id
                name
                parentId
              }
            }
          }
        }
      `;

      const variables = {
        filters,
        page: {
          page,
          resultsPerPage,
        },
      };

      return apolloProvider.clients.clientEvents.query({
        query: eventsQuery,
        variables,
        client: 'clientEvents',
      })
        .then((response) => {
          const { events, totalResults } = response.data.eventQuery;
          const resultEvents = [];
          let categories = [];

          events.forEach((event) => {
            const thisEvent = { ...event }; // Clone object because source is read only.

            // Place images marked with isMain at the start and get url with imagesize.
            thisEvent.images = this.sortImages(
              thisEvent.images,
              constantImageSizes.VANPLAN_RELATED_INFIX,
            );

            resultEvents.push(thisEvent);

            categories = categories.concat(thisEvent.categories);
          });

          // Only keep the parent categories.
          categories = _.remove(categories, (n) => _.isNull(n.parentId));

          // Only unique values in array.
          categories = _.uniqWith(categories, _.isEqual);

          // Sort the categories.
          categories.sort(this.sortCategories);

          return {
            events: resultEvents,
            categories,
            totalResults,
          };
        });
    },
    inputValidate: (event) => {
      if (event.target.value !== null && event.target.value !== '') {
        // Value is not emtpy, add class.
        event.target.classList.add('vp-valid-input');
      } else {
        // Value is empty, remove class.
        event.target.classList.remove('vp-valid-input');
      }
    },
    sortImages: (images, size) => {
      let allImages = images;

      // Place images marked with isMain at the start.
      allImages = _.sortBy(allImages, (o) => (o.isMain ? 0 : 1));

      // Get the url and copyright and insert image size in the url for every image.
      allImages.forEach((e, index) => {
        // Get everything after the last /
        // Sometimes we get an url without an extension e.g. https://www.vanplan.nl/contentfiles/null
        // So we can't just get the extension after the last .
        let url = e.url.substr(1 + e.url.lastIndexOf('/'));
        const ext = url.split(/[#?]/)[0].split('.').pop().trim();

        if (ext !== 'null') {
          url = e.url.replace(`.${ext}`, `${size}.${ext}`);
        } else {
          url = e.url;
        }

        allImages[index] = {
          url,
          copyright: e.copyright,
        };
      });

      return allImages;
    },
    // Checks if an object has a nested property.
    hasNestedProperty: (obj, propertyPath) => {
      if (!propertyPath) {
        return false;
      }

      var properties = propertyPath.split('.');

      for (var i = 0; i<properties.length; i++) {
        var prop = properties[i];

        if (!obj || !Object.prototype.hasOwnProperty.call(obj, prop)) {
          // Property doesn't exist.
          return false;
        } else {
          // Property exists, check for the next one.
          obj = obj[prop];
        }
      }

      return true;
    },
  }
}
