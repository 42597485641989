<template>
  <router-link
    :to="{ name: 'event', params:{ id: generatedSlug() } }"
    class="featured-block"
  >
    <div class="image-container">
      <img v-if="event.images.length" :src="event.images[0].url" width="360" height="270" />
      <img v-else :src="getPlaceHolderUrl()" width="360" height="270" />
    </div>
    <div class="information-container">
      <h2 class="featured-title">{{ event.title }}</h2>
      <div class="featured-meta">
        <span v-if="event.location.name" class="vp-location">
          {{ event.location.name }}
          <span v-if="event.location.cityName">, {{ setToLowerCase(event.location.cityName) }}</span>
        </span>
        <span v-if="event.startDate && !validEndDate" class="vp-date">
          {{ formatDate(event.startDate) }}
        </span>
        <span
          v-else-if="event.startDate && validEndDate"
          class="vp-end-date"
        >
          {{ formatDate(event.startDate, 'dddd D MMMM', 'dddd D MMMM YYYY', '', true) }} -
          {{ formatDate(event.endDate) }}
        </span>
        <span v-if="event" class="vp-price">{{ formatEventprice(event) }}</span>
      </div>
    </div>
  </router-link>
</template>

<script>
import mixin from '../mixins/mixin';

export default {
  name: 'eventFeatured',
  mixins: [mixin],
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  computed: {
    validEndDate() {
      return this.formatDate(this.event.endDate) !== 'Invalid date'
      && this.formatDate(this.event.startDate) !== this.formatDate(this.event.endDate);
    }
  },
  methods: {
    getPlaceHolderUrl() {
      return `${process.env.VUE_APP_VANPLAN_BASEURL}/img/vanplan_placeholder.png`;
    },
    setToLowerCase(param) {
      return param.toLowerCase();
    },
    generatedSlug() {
      return (
        this.event.title + '-' +
        this.event.location.name + '-' +
        this.event.location.cityName + '-' +
        this.event.id)
        .toLowerCase()
        .replaceAll(' ', '-')
        .replace(/[^a-zA-Z-0-9]+/g, '');
    },
  },
};
</script>

<style scoped lang="scss">
@import '../assets/scss/_variables.scss';

.featured-block {
  display: flex;
  flex-direction: column;
  height: 100%;

  .image-container {
    flex-shrink: 0;
    height: 13.875rem;
    width: 100%;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  .information-container {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 0 0 -#{$spacing-3xl} $spacing-xs; // margin-bottom same value as top
    padding: $spacing-xs 0 0 $spacing-xs;
    position: relative;
    top: -$spacing-3xl;
    width: calc(100% - #{$spacing-xs});

    .featured-title {
      font-size: $fs-lg;
      line-height: $lh-xs;
      font-weight: $fw-bold;
      margin-bottom: $spacing-6xs;
    }

    .featured-meta {
      align-items: center;
      color: $grey-alt;
      display: flex;
      flex-wrap: wrap;
      font-size: $fs-xs;
      line-height: $lh-regular;

      .vp-location {
        flex-basis: 100%;
        
        span {
          text-transform: capitalize;
        }
      }

      .vp-date:first-letter {
        text-transform: uppercase;
      }

      .vp-price::before {
        content: '-';
        margin: 0 0.3125rem;
      }
    }
  }

  @media #{$tablet-and-above} {
    .information-container {
      .featured-title {
        font-size: $fs-4xl;
        margin-bottom: $spacing-xs;
      }

      .featured-meta {
        font-size: $fs-regular;
        line-height: $lh-lg;
      }
    }

  }
}
</style>
